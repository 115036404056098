import { Button, ThemeProvider } from '@mui/material';
import Styles from './styles';
import { TBotaoProps } from './@types/types';
import { EmptyStr } from '../../@const/constantes';
import Tema from '../../theme/tema';

const definirProps = (props: TBotaoProps) => {
  const objRetorno: TBotaoProps = { ...props };

  objRetorno.variant = 'contained';
  objRetorno.startIcon = EmptyStr;
  objRetorno.endIcon = EmptyStr;

  switch (props.modo) {
    case 'padrao':
      objRetorno.color = 'primary';
      break;
    case 'custom':
      objRetorno.sx = props.sx;
      objRetorno.variant = props.variant;
      objRetorno.startIcon = props.startIcon;
      objRetorno.endIcon = props.endIcon;
      objRetorno.color = props.color;
      break;
    default:
    // objRetorno.color = Cores.verde;
  }

  if (props.modo !== 'custom') {
    switch (props.tamanho) {
      case 'pequeno':
        objRetorno.sx = {
          maxWidth: '180px',
          width: '14vw',
        };
        break;
      case 'medio':
        objRetorno.sx = {
          maxWidth: '215px',
          width: '25vw',
        };
        break;
      case 'grande':
        objRetorno.sx = {
          Maxwidth: '688px',
          width: '50vw',
        };
        break;
      case 'responsivo':
        objRetorno.sx = {
          width: '100%',
        };
        break;
      default:
        objRetorno.sx = {
          width: '100%',
        };
    }
  }

  objRetorno.sx = {
    ...objRetorno.sx,
    borderRadius: '10px',
    height: '45px',
    minWidth: 'fit-content',
  }

  return objRetorno;
};

const Botao = (props: TBotaoProps) => {
  const style = Styles();
  const propsDefinidas: TBotaoProps = { ...definirProps(props) };

  return (
    <ThemeProvider theme={Tema}>
      <Button sx={style.botao} {...propsDefinidas} />
    </ThemeProvider>
  );
};

export default Botao;
