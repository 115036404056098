import { FormControl, Stack, TextField, TextFieldProps } from "@mui/material"
import CaixaDeTexto from "../../componentes/caixa-de-texto"
import Controlador from "./controlador";
import { Mascaras } from "../../theme/mascaras";
import Mascara from "../../componentes/mascara";
import Botao from "../../componentes/botao";
import Styles from './styles'
import CaixaDeOpcoesAutocomplete from "../../componentes/caixa-de-opcoes-autocomplete";
import { useState } from "react";
import { TGenero } from "./@types/types";
import tratamentos from "../../utils/tratamentos";
import { EmptyStr } from "../../@const/constantes";

const CadastroCliente = () => {
  const controlador = Controlador();
  const estilo = Styles();

  let arrayGeneros: Array<TGenero> = [
    {
      id: 1,
      nome: 'Masculino'
    },
    {
      id: 2,
      nome: 'Feminino'
    },
    {
      id: 3,
      nome: 'Não-Binário'
    },
    {
      id: 4,
      nome: 'Outros'
    },
    {
      id: 5,
      nome: 'Não Informar'
    }
  ]
  const [generoSelecionado, setGeneroSelecionado] = useState(arrayGeneros[0])

  const handleValueIdGenero = (newValue: TGenero) => {
    controlador.values.idGenero = newValue?.id ?? 1;
    setGeneroSelecionado(newValue);
  }

  return (
    <FormControl
      component='form'
      onSubmit={controlador.handleSubmit}
      onBlur={controlador.handleBlur}
      onChange={controlador.handleChange}
      noValidate
      sx={estilo.containerFormulario}
    >
      <Stack sx={estilo.container}>
        <Mascara
          mascara={Mascaras.cpf}
          input={
            <CaixaDeTexto
              name='cpf'
              modo="padrao"
              label='CPF'
              required
              sx={estilo.input}
              autoComplete='off'
              error={!!(controlador?.errors?.cpf && controlador?.touched?.cpf)}
              helperText={controlador?.errors?.cpf && controlador?.touched?.cpf ? controlador.errors.cpf : EmptyStr}
            />
          }
        />

        <CaixaDeTexto
          name='nome'
          modo="padrao"
          label='Nome Completo'
          required
          sx={estilo.input}
          error={!!(controlador?.errors?.nome && controlador?.touched?.nome)}
          helperText={controlador?.errors?.nome && controlador?.touched?.nome ? controlador.errors.nome : EmptyStr}
        />

        <CaixaDeOpcoesAutocomplete
          id='idGenero'
          modo='padrao'
          disableClearable
          estilo={estilo.input}
          options={arrayGeneros}
          onChange={(e, newValue: any) => handleValueIdGenero(newValue as TGenero)}
          value={generoSelecionado}
          getOptionLabel={(option: any) => option.nome}
          renderInput={(props: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField
              {...props}
              name='idGenero'
              label='Gênero'
              required
            />
          )}
        />

        <CaixaDeTexto
          name='dataNascimento'
          modo='padrao'
          label='Data de Nascimento'
          type='date'
          required
          sx={[estilo.input, estilo.inputTypeDate]}
          onChange={controlador.handleChange}
          error={!!(controlador?.errors?.dataNascimento && controlador?.touched?.dataNascimento)}
          helperText={controlador?.errors?.dataNascimento && controlador?.touched?.dataNascimento ? controlador.errors.dataNascimento : EmptyStr}
        />

        <Mascara
          mascara={
            tratamentos.removerCaracteresEspeciais(controlador?.values?.telefone).replace(/\s/g, '').substring(3, 2) === '9'
              ? Mascaras.celular
              : Mascaras.telefone
          }
          input={
            <CaixaDeTexto
              name='telefone'
              modo='padrao'
              label='Tel celular / Fixo'
              required
              sx={estilo.input}
              error={!!(controlador?.errors?.telefone && controlador?.touched?.telefone)}
              helperText={controlador?.errors?.telefone && controlador?.touched?.telefone ? controlador.errors.telefone : EmptyStr}
            />

          }
        />

        <CaixaDeTexto
          name='email'
          modo="padrao"
          label='E-mail'
          required
          sx={estilo.input}
          error={!!(controlador?.errors?.email && controlador?.touched.email)}
          helperText={controlador?.errors?.email && controlador?.touched.email ? controlador.errors.email : EmptyStr}
        />

        <Botao
          id='btnGravar'
          type='submit'
          modo='padrao'
          tamanho='responsivo'
        >
          GRAVAR
        </Botao>
      </Stack>
    </FormControl>
  )
}

export default CadastroCliente