import { ReactNode } from 'react';
import InputMask from 'react-input-mask';
import { TMascaraProps } from './@types/types'

const Mascara = (props: TMascaraProps) => {
  return (
    <InputMask
      mask={props.mascara}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
    >
      {(() => props.input) as unknown as ReactNode}
    </InputMask>
  );
};

export default Mascara;
