import { Autocomplete, CircularProgress } from '@mui/material';
import { Styles } from './styles';
import { TAutoCompleteProps } from './@types/types';

const definirProps = (props: TAutoCompleteProps): TAutoCompleteProps => {
  const objRetorno: TAutoCompleteProps = { ...props };

  switch (props.modo) {
    case 'padrao': {
      return objRetorno;
    }
    default: {
      return objRetorno;
    }
  }
};

const CaixaDeOpcoesAutocomplete = (props: TAutoCompleteProps) => {
  const { isLoading, ...propsDefinidas }: TAutoCompleteProps = { ...definirProps(props) };
  const estilo = Styles();

  return (
    <Autocomplete
      {...propsDefinidas}
      sx={estilo.auto}
      popupIcon={isLoading ? <CircularProgress size={14} sx={{ marginTop: '3px' }} /> : undefined}
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando...'
    />
  );
};

export default CaixaDeOpcoesAutocomplete;
