import React, { createContext, useContext, useState } from 'react';

type TCarregamentoContextoProps = {
  isProgressoCircular: boolean;
  iniciar: () => void;
  pausar: () => void;
}

type TCarregamentoProviderProps = {
  children: React.ReactNode;
}

const ContextoCarregamento = createContext<TCarregamentoContextoProps>(
  {} as TCarregamentoContextoProps
);

const CarregamentoProvider = ({ children }: TCarregamentoProviderProps) => {
  const [isProgressoCircular, setProgressoCircular] = useState(false);
  const iniciar = () => {
    setProgressoCircular(true);
  }
  const pausar = () => {
    setProgressoCircular(false);
  }
  return (
    <ContextoCarregamento.Provider
      value={{
        isProgressoCircular,
        iniciar,
        pausar
      }}>
      {children}
    </ContextoCarregamento.Provider>
  );
};

const useProgresso = () => {
  const contextoProgressoCircular = useContext(ContextoCarregamento);
  return contextoProgressoCircular;
};

export { ContextoCarregamento, CarregamentoProvider, useProgresso };
