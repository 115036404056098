import { Components, Theme, createTheme } from '@mui/material';
import Cores from './cores';

declare module '@mui/material/styles' {
  interface PaletteColor {
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }

  interface Palette {
    preto: Palette['primary'];
    branco: Palette['primary'];
    cinza: Palette['primary'];
    amarelo: Palette['primary'];
    verde: Palette['primary'];
    vermelho: Palette['primary'];
    azul: Palette['primary'];
  }

  interface PaletteOptions {
    preto: PaletteOptions['primary'];
    branco: PaletteOptions['primary'];
    cinza: PaletteOptions['primary'];
    amarelo: PaletteOptions['primary'];
    verde: PaletteOptions['primary'];
    vermelho: PaletteOptions['primary'];
    azul: PaletteOptions['primary'];
  }
}

/**
 * Override da interface de cores do objeto para poder aplicar as cores novas
 */
// declare module '@mui/material/Button' {
//   interface ButtonPropsColorOverrides {
//     amarelo: true;
//   }
// }

// declare module '@mui/material/styles' {
//   interface BreakpointOverrides {
//     breakpoints: {
//       values: {
//         xs: false; // removes the `xs` breakpoint
//         sm: false;
//         md: false;
//         lg: false;
//         xl: false;
//         mobile: 0; // adds the `mobile` breakpoint
//         tablet: 500;
//         laptop: 600;
//         desktop: 800;
//       },
//     },
//   }
// }

/**
 * Objeto de customização dos componentes da material
 */
const CustomComponents: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontSize: 16,
        color: Cores.cinza['100'],
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: Cores.cinza[300],
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: Cores.cinza['300'],
        backgroundColor: Cores.branco,
        fontSize: '1.2rem',
      },
    },
  },
};

const Tema = createTheme({
  components: CustomComponents,
  palette: {
    primary: {
      main: Cores.primaria[100],
      light: Cores.primaria[200],
      dark: Cores.primaria[300],
      darker: Cores.primaria[400],
    },
    preto: {
      main: Cores.preto,
    },
    branco: {
      main: Cores.branco,
    },
    cinza: {
      main: Cores.cinza[100],
      light: Cores.cinza[100],
      dark: Cores.cinza[200],
    },
    amarelo: {
      main: Cores.amarelo,
    },
    verde: {
      main: Cores.verde,
    },
    vermelho: {
      main: Cores.vermelho,
    },
    azul: {
      main: Cores.azul,
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Segoe UI', 'sans-serif'].join(','),
    h1: {
      fontSize: '5rem',
    },
    h2: {
      fontSize: '3rem',
    },
    h3: {
      fontSize: '1rem',
    },
  },
});

export default Tema;
