import { RouteObject } from 'react-router-dom';
import CadastroCliente from '../screens/cadastro-cliente';

/**
 * Rotas da aplicação
 * @returns RouteObject - Objeto de rotas do react-router-dom
 */
const Rotas = (): RouteObject[] => {
  return ([
    {
      path: "/",
      element: <CadastroCliente />,
    }
  ]);
}

export default Rotas;
