import Cores from '../../theme/cores';

const Styles = () => {
  return {
    botao: {
      backgroundColor: Cores.verde,
      color: Cores.cinza[100],
    },
  };
};

export default Styles;
