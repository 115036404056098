import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Validacoes from '../../utils/validacoes';
import { TCliente, TClienteVazio, TJsonEnvioCadastroCliente } from './@types/types';
import tratamentos from '../../utils/tratamentos';
import { gravarDadosCadastroCliente } from './requests';
import { Mensagens } from '../../sistema/@types/types';
import { useProgresso } from '../../context/contexto-carregamento';
import { EmptyStr } from '../../@const/constantes';
import { useNavigate } from 'react-router-dom';

const montarJsonCadastroClientes = (AValues: TCliente): TJsonEnvioCadastroCliente => {
  const dados = {
    cliente: {
      cpf: tratamentos.removerCaracteresEspeciais(AValues.cpf),
      nome: AValues.nome,
      generoId: AValues.idGenero,
      dataNascimento: AValues.dataNascimento,
      telefone: tratamentos.removerCaracteresEspeciais(AValues.telefone).replace(/\s/g, EmptyStr),
      email: AValues.email,
    },
  };

  return dados;
};

const Controlador = () => {
  const navigate = useNavigate();
  const progresso = useProgresso();
  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: TClienteVazio,
    validationSchema: Yup.object({
      cpf: Yup.string()
        .test('CpfInvalido', 'CPF não pode ser vazio', (cpf) => {
          return Validacoes.CPF(cpf as string).vazio();
        })
        .test('cnpjInvalido', 'CPF deve conter 11 dígitos', (cpf) => {
          return Validacoes.CPF(cpf as string).tamanho();
        })
        .test('cnpjInvalido', 'O CPF informado é inválido', (cpf) => {
          return Validacoes.CPF(cpf as string).valido();
        }),
      nome: Yup.string().test('nomeInvalido', 'Nome inválido', (nome) => {
        return Validacoes.Nome(nome as string);
      }),
      idGenero: Yup.string().test('generoInvalido', 'Gênero inválido', (idGenero) => {
        return Validacoes.IdGenero(idGenero as string);
      }),
      dataNascimento: Yup.string().test('dataNascimentoInvalida', 'Data de nascimento inválida', (dataNascimento) => {
        return Validacoes.DataNascimento(dataNascimento as string);
      }),
      telefone: Yup.string().test('telefoneInvalido', 'Telefone inválido', (telefone) => {
        return Validacoes.Telefone(telefone as string);
      }),
      email: Yup.string().test('EmailValido', 'E-mail inválido', (email) => {
        return Validacoes.Email(email as string);
      }),
    }),
    onSubmit: async (values: TCliente, { resetForm }) => {
      progresso.iniciar();
      const objCliente = montarJsonCadastroClientes(values);

      try {
        await gravarDadosCadastroCliente(objCliente);
        toast.success('Cliente cadastrado com sucesso!');

        resetForm({ values: TClienteVazio });

        navigate(0); //remover
      } catch (error: unknown) {
        const erros = error as any; //deveria ser TCadastroClienteResposta mas a api ora retorna um array de Mensagens ora retorna um objeto Mensagens;

        if (erros?.response?.data?.retorno?.mensagens.codigo) {
          const codigo = erros.response.data.retorno.mensagens.codigo;
          const descricao = erros.response.data.retorno.mensagens.descricao;

          toast.error(`${codigo} - ${descricao}`);
        } else if (erros?.response?.data?.retorno?.mensagens) {
          erros.response?.data?.retorno?.mensagens?.forEach((erro: Mensagens) => {
            toast.error(`${erro.codigo} - ${erro.descricao}`);
          });
        } else {
          toast.error('Erro ao cadastrar cliente');
        }
      } finally {
        progresso.pausar();
      }
    },
  });

  return formik;
};

export default Controlador;
