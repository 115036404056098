import { TCores } from "./@types/types"

const Cores: TCores = {
  primaria: {
    100: '#42AF6A',
    200: '#42AF6A',
    300: '#42AF6A',
    400: '#42AF6A'
  },
  preto: '#000000',
  branco: '#FFFFFF',
  cinza: {
    100: '#F0F0F0',
    200: '#AAB2AB',
    300: '#212121',
  },
  amarelo: '#FFE95E',
  verde: '#42AF6A',
  vermelho: '#DE2F43',
  azul: '#0088C8',
  transparente: '#00000000'
}

export default Cores