import { ThemeProvider } from '@mui/material/styles';
import Tema from './theme/tema';
import { CarregamentoProvider, } from './context/contexto-carregamento';
import ProgressoCircular from './componentes/progresso';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './App.css';
import Rotas from './routes';

const router = createBrowserRouter(Rotas());

const ProgressoCarregamento = () => {
  return (
    <ProgressoCircular
      modo='padrao'
      size={80}
    />
  )
}

const ContainerToast = () => {
  return (
    <ToastContainer
      position='top-right'
      autoClose={30000}
      hideProgressBar={false}
      newestOnTop={false}
      draggable={false}
      closeOnClick
      style={{ whiteSpace: 'pre-line' }}
      pauseOnHover
      theme={'light'}
    />
  )
}

function App() {
  return (
    <ThemeProvider theme={Tema}>
      <CarregamentoProvider>
        <ProgressoCarregamento />
        <ContainerToast />
        <RouterProvider router={router} />
      </CarregamentoProvider>
    </ThemeProvider>
  );

}

export default App;
