import { IconButton, TextField, ThemeProvider } from '@mui/material';
import { useState } from 'react';
import { Icones } from '../../theme/icones';
import { TCaixaDeTextoProps } from './@types/types';
import { EmptyStr } from '../../@const/constantes';
import Tema from '../../theme/tema';

const DefinirProps = (props: TCaixaDeTextoProps) => {
  const objRetorno: TCaixaDeTextoProps = { ...props };
  const [mostrarSenha, setMostrarSenha] = useState<boolean>(false);

  switch (props.modo) {
    case 'padrao': {
      objRetorno.variant = 'outlined';
      objRetorno.type = props.type === EmptyStr ? 'text' : props.type;

      break;
    }
    case 'senha': {
      objRetorno.variant = 'outlined';
      objRetorno.type = mostrarSenha ? 'text' : 'password';
      objRetorno.InputProps = {
        endAdornment: (
          <IconButton
            id='iconeOlho'
            onClick={() => setMostrarSenha(!mostrarSenha)}
            edge='end'
            sx={{
              marginRight: '-8px',
            }}
          >
            {mostrarSenha ? Icones.olhoFechado() : Icones.olhoAberto()}
          </IconButton>
        ),
      };
      break;
    }
  }

  objRetorno.size = props.tamanho === 'medio' ? 'medium' : 'small';
  objRetorno.InputLabelProps = { shrink: true }

  return objRetorno;
};

const CaixaDeTexto = (props: TCaixaDeTextoProps) => {
  const propsDefinidas: TCaixaDeTextoProps = { ...DefinirProps(props) };

  return (
    <ThemeProvider theme={Tema}>
      <TextField {...propsDefinidas} />
    </ThemeProvider>
  );
};

export default CaixaDeTexto;
