import { useTheme } from '@mui/material/styles';

const Styles = () => {
  const theme = useTheme();
  return {
    containerFormulario: {
      minHeight: '100%',
      padding: '20px 0',
      display: 'flex',
      flexDirection: 'row',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '0 auto',
      [theme.breakpoints.up('xs')]: {
        gap: '30px',
        width: '70%',
      },
      [theme.breakpoints.only('sm')]: {
        gap: '30px',
        width: '60%',
      },
      [theme.breakpoints.only('md')]: {
        gap: '30px',
        width: '60%',
      },
      [theme.breakpoints.only('lg')]: {
        gap: '20px',
        width: '40%',
      },
      [theme.breakpoints.only('xl')]: {
        gap: '30px',
        width: '30%',
      },
      maxWidth: '900px',
    },
    input: {
      width: '100%',
      display: 'flex',
    },
    inputTypeDate: {
      'input[type="date"]::-webkit-calendar-picker-indicator': {
        display: 'none',
      },
    },
  };
};

export default Styles;
