/* eslint-disable import/no-anonymous-default-export */
import { EmptyStr } from '../@const/constantes';
import Tratamentos from './tratamentos';

class Validacoes {
  public Nome(ANome: string): boolean {
    if (ANome) {
      return ANome.length >= 3 && ANome.length <= 60;
    }
    return false;
  }

  public Telefone = (ATelefone: string): boolean => {
    if (ATelefone) {
      let foneFormatado = Tratamentos.removerCaracteresEspeciais(ATelefone).split(' ').join('');
      if (
        foneFormatado !== 'undefined' &&
        foneFormatado !== null &&
        foneFormatado !== undefined &&
        foneFormatado !== ' ' &&
        foneFormatado.length > 0
      ) {
        return foneFormatado.substring(2, 3) === '9' ? foneFormatado.length === 11 : foneFormatado.length === 10;
      }
    }

    return false;
  };

  public CEP = (ACEP: string): boolean => {
    if (ACEP) {
      const cep = Tratamentos.removerCaracteresEspeciais(ACEP);
      return cep.length === 8;
    }
    return false;
  };

  public Email = (AEmail: string, AObrigatorio: boolean = true): boolean => {
    if (AEmail && AEmail.length <= 60) {
      const regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+@[a-zA-Z0-9]+\.[a-zA-Z]+(\.[a-zA-Z]{2})?$/;
      return regEx.test(AEmail);
    }
    return !AObrigatorio;
  };

  public Logradouro = (ALogradouro: string): boolean => {
    if (ALogradouro) {
      return ALogradouro.length >= 3 && ALogradouro.length <= 60;
    }
    return false;
  };

  public NumeroEndereco = (ANumeroEndereco: string): boolean => {
    if (ANumeroEndereco) {
      if (ANumeroEndereco === 'S/N') {
        return true;
      } else {
        return ANumeroEndereco.length >= 1 && ANumeroEndereco.length <= 6;
      }
    }
    return false;
  };

  public Bairro = (ABairro: string): boolean => {
    if (ABairro) {
      return ABairro?.length >= 1 && ABairro?.length <= 60;
    }
    return false;
  };

  public IdGenero = (AIdGenero: string): boolean => {
    return this.isNumber(AIdGenero) && parseInt(AIdGenero) !== 0;
  };

  public Senha(ASenha: string) {
    return {
      tamanho: (): boolean => {
        if (ASenha) {
          return ASenha.length >= 8 && ASenha.length <= 12;
        }
        return false;
      },
    };
  }

  public ConfirmarSenha(ASenha: string): boolean {
    return ASenha ? true : false;
  }

  public DataNascimento(ADataNascimento: string): boolean {
    if (ADataNascimento) {
      return ADataNascimento !== EmptyStr;
    }
    return false;
  }

  public CPF(ACPF: string) {
    return {
      vazio: (): boolean => {
        return Tratamentos.removerCaracteresEspeciais(ACPF) !== EmptyStr;
      },

      tamanho: (): boolean => {
        const CPF = Tratamentos.removerCaracteresEspeciais(ACPF);
        return CPF.length >= 11;
      },

      valido: (): boolean => {
        if (ACPF) {
          const cpf = ACPF.replace(/[^\d]+/g, '');

          // Elimina CPFs invalidos conhecidos
          if (
            cpf === EmptyStr ||
            cpf.length !== 11 ||
            cpf === '00000000000' ||
            cpf === '11111111111' ||
            cpf === '22222222222' ||
            cpf === '33333333333' ||
            cpf === '44444444444' ||
            cpf === '55555555555' ||
            cpf === '66666666666' ||
            cpf === '77777777777' ||
            cpf === '88888888888' ||
            cpf === '99999999999'
          )
            return false;

          // Valida 1o digito
          let add = 0;

          for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);

          let rev = 11 - (add % 11);

          if (rev === 10 || rev === 11) rev = 0;

          if (rev !== parseInt(cpf.charAt(9))) return false;

          // Valida 2o digito
          add = 0;

          for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);

          rev = 11 - (add % 11);

          if (rev === 10 || rev === 11) rev = 0;

          if (rev !== parseInt(cpf.charAt(10))) return false;

          return true;
        }

        return false;
      },
    };
  }

  public isNumber(ATexto: string): boolean {
    return !isNaN(Number(ATexto));
  }
}

export default new Validacoes();
