import CircularProgress from '@mui/material/CircularProgress';
import { useContext } from 'react';
import Styles from './styles';
import { TProgressoCircularProps } from './@types/types'
import { ContextoCarregamento } from '../../context/contexto-carregamento';

const ProgressoCircular = (props: TProgressoCircularProps) => {
  const { isProgressoCircular } = useContext(ContextoCarregamento);
  const style = Styles();

  return (
    <>
      {isProgressoCircular === true ? (
        <div className='container' style={style.Container}>
          <CircularProgress
            {...props}
            style={style.Progresso}
          />{' '}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default ProgressoCircular;
