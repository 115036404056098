import Requisicao from '../../services/http/http.service';
import { TJsonEnvioCadastroCliente } from './@types/types';

/**
 * Método responsável por chamar a função de cadastro de empresa conveniada no banco do The One
 * @param AObjCliente Objeto JSon formatado para gravar os dados de empresa conveniada no banco do The One
 * @returns
 */
export const gravarDadosCadastroCliente = async (AObjCliente: TJsonEnvioCadastroCliente): Promise<Object> => {
  return new Promise(async (resolve, reject) => {
    await new Requisicao().FidelidadeAPI().post('/cliente/cadastrar', AObjCliente).then(resolve).catch(reject);
  });
};
