import Cores from '../../theme/cores';

type TProps = {
  Container: Object;
  Progresso?: Object;
};

function adicionarOpacidade(color: string, opacity: number): string {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

const Estilos = (): TProps => {
  return {
    Container: {
      width: '100%',
      height: '100vh',
      position: 'absolute',
      textAlign: 'center',
      zIndex: '1001',
      backgroundColor: adicionarOpacidade(Cores.preto, 0.3),
    },
    Progresso: {
      opacity: 1,
      position: 'relative',
      top: '40.49vh',
    },
  };
};

export default Estilos;
