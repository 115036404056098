/* eslint-disable import/no-anonymous-default-export */

import { EmptyStr } from '../@const/constantes';
import { ITratamentos } from './@types/types';

enum ListaErros {
  ERRO_CONEXAO = 'Network Error',
  TIMEOUT = 'timeout',
  STATUS_400 = 'status code 400',
  STATUS_401 = 'status code 401',
}

class Tratamentos implements ITratamentos {
  static readonly ListaErros = ListaErros;
  readonly ListaErros = Tratamentos.ListaErros;

  public tratarMensagemRetorno(AMensagem: string) {
    let Result: string = AMensagem;

    if (AMensagem.includes(ListaErros.ERRO_CONEXAO)) {
      Result = 'Erro de conexão, verifique se você ainda está Online.';
    }
    if (AMensagem.includes(ListaErros.TIMEOUT)) {
      Result = 'A aplicação demorou mais do que o esperado para responder, verifique sua conexão.';
    }
    if (AMensagem.includes(ListaErros.STATUS_400)) {
      Result = 'Ocorreu um erro de comunicação com Status 400.';
    }
    if (AMensagem.includes(ListaErros.STATUS_401)) {
      Result = 'Seu usuário não está autenticado.';
    }

    return Result;
  }

  public removerCaracteresEspeciais(ATextoEntrada: string | number) {
    const regEx = /[^a-zA-Z0-9\s]/g;
    const entradaString = `${ATextoEntrada}`;

    return entradaString?.replace(regEx, '');
  }

  public filtraNumeros(ATextoEntrada: string | number) {
    const regEx = /[^0-9]+/g;
    const entradaString = `${ATextoEntrada}`;
    return ATextoEntrada === 'S/N' ? 'S/N' : entradaString?.replace(regEx, '');
  }

  public removerAcentos(ATextoEntrada: string) {
    return ATextoEntrada.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  public ConverterBRLUSD = (valor: string) => {
    valor = valor.replace('.', '');
    valor = valor.replace(',', '');

    const valorAnterior = valor.substring(0, valor.length - 2);
    const valorDepois = valor.substring(valor.length - 2, valor.length);

    valor = valorAnterior + '.' + valorDepois;

    return valor;
  };

  public formatarTelefone(telefone: string) {
    if (telefone) {
      if (telefone.length === 11) {
        telefone = telefone.replace(/\D/g, '');
        telefone = telefone.replace(/^(\d{2})(\d)/g, '($1) $2');
        telefone = telefone.replace(/(\d)(\d{4})$/, '$1-$2');
      } else {
        telefone = telefone.replace(/\D/g, '');
        telefone = telefone.replace(/^(\d{2})(\d)/g, '($1) $2');
        telefone = telefone.replace(/(\d)(\d{4})$/, '$1-$2');
      }
    }
    return telefone;
  }

  public formatarCEP(cep: string) {
    if (cep) {
      cep = cep.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3');
    }
    return cep;
  }

  public formatarCEST(cest: string) {
    if (cest) {
      cest = cest.replace(/(\d{2})(\d{3})(\d{2})/, '$1.$2.$3');
    }
    return cest;
  }

  public formatarCPF(cpf: string) {
    if (cpf) {
      cpf = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    return cpf;
  }

  public formatarCNPJ(cnpj: string) {
    if (cnpj) {
      cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    return cnpj;
  }

  public formatarNCM(ncm: string) {
    if (ncm) {
      ncm = ncm.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3');
    }
    return ncm;
  }

  public formatarData(AData: string) {
    if (AData) {
      AData = new Date(AData).toISOString();
    }
    return AData;
  }

  /**
   * Método responsável por receber um objeto e aplicar EmptyStr em atributos com valor null
   * @param AObjeto Objeto proveniente da requisição dentro do atributo "dados : {}"
   * @returns object - objeto pronto para aplicar ao controlador do formulario.
   */
  public SetarNullParaString(AObjeto: object): object {
    const setDefault = (obj: any) => {
      Object.keys(obj).forEach((key: any) => {
        if (typeof obj[key] === 'object') {
          if (obj[key] == null || !Object.keys(obj[key])) {
            obj[key] = '';
          } else {
            setDefault(obj[key]);
          }
        }
      });
    };
    setDefault(AObjeto);
    return AObjeto;
  }

  public FormatarValoresBRL(Avalor: string | number, ACasasDecimais: number): string {
    let final = EmptyStr;

    Avalor = `${Avalor}`.replace(/[^\d,]/, '');

    if (Avalor === undefined || Avalor === EmptyStr) {
      Avalor = '0.0000';
    }

    let array = Avalor?.split(EmptyStr);

    if (array?.length === 1) {
      array.reverse().splice(1, 0, '0');
      array.splice(1, 0, '0');
      array.reverse();
    } else if (array?.length === 3) {
      array.splice(0, 0, '0');
    }

    if (array?.length === ACasasDecimais + 1) {
      array.reverse().splice(ACasasDecimais, 0, ',');
      array = array.reverse();
    } else if (array?.length > ACasasDecimais + 1) {
      if (array?.includes('.')) {
        let pos = array.reverse().findIndex((virg: string) => virg === ',');

        if (pos === -1) {
          array.splice(0, 4 - ACasasDecimais);
          array.splice(array.indexOf('.'), 1);
        }
        array.reverse();
      }

      array = array.filter((char: string) => !char.includes('.'));
      array = array.filter((char: string) => !char.includes(','));

      if (array?.length === 4) {
        if (array[0] === '0') {
          array.splice(0, 1);
        }
      }

      array.reverse().splice(ACasasDecimais, 0, ',');
      array = array.reverse();

      array = array.filter((char: string) => !char.includes('.'));
      array.reverse().forEach((element: any, index: number) => {
        if (element.includes(',')) {
          if (array.length >= 7) {
            array.splice(index + 4, 0, '.');
          }
          if (array.length >= 11) {
            array.splice(index + 8, 0, '.');
          }
          if (array.length >= 15) {
            array.splice(index + 12, 0, '.');
          }
          if (array.length >= 19) {
            array.splice(index + 16, 0, '.');
          }
          if (array.length >= 23) {
            array.splice(index + 20, 0, '.');
          }
        }
      });

      array.reverse();
    }

    if (array?.filter((char: string) => char.includes(',')).length > 1) {
      array.reverse().splice(ACasasDecimais, 1);
      array = array.reverse();
    }

    final = array?.join(EmptyStr).toString();

    return final;
  }
}

export default new Tratamentos();
