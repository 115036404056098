import axios, { AxiosInstance } from 'axios';
// import TokenService from '../token/token.service';
import { IRequests } from './interface';

class Requisicao implements IRequests {
  // constructor(private ctx: any = null) {}

  public FidelidadeAPI(isLogin: boolean = false): AxiosInstance {
    const Instancia = axios.create({
      baseURL: process.env.REACT_APP_URL_BASE_API_FIDELIDADE,
      timeout: 60000,
      // headers: {
      //   authorization: TokenService.getAccessToken(this.ctx),
      // },
    });

    // if (!isLogin) {
    //   Instancia.interceptors.response.use(
    //     (response) => {
    //       return response;
    //     },

    //     async (error) => {
    //       if (error?.response?.status === 401) {
    //         try {
    //           const resp = await axios.post(
    //             `${process.env.REACT_APP_URL_BASE_API_FIDELIDADE}autenticacao/refresh-token`,
    //             {},
    //             {
    //               headers: {
    //                 refresh_token: TokenService.getRefreshToken(this.ctx),
    //               },
    //             }
    //           );

    //           TokenService.setAccessToken(resp.data?.retorno?.dados?.token, this.ctx);
    //           error.config.headers.authorization = resp.data?.retorno?.dados?.token;

    //           const newResponse = await axios(error.config);

    //           return newResponse;
    //         } catch (error: any) {
    //           return Promise.reject(error);
    //         }
    //       } else {
    //         return Promise.reject(error);
    //       }
    //     }
    //   );
    // }

    return Instancia;
  }

  public CustomRequest = () => {
    return axios.create({
      timeout: 15000,
      withCredentials: false,
    });
  };
}

export default Requisicao;
