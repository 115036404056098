import { EmptyStr } from '../../../@const/constantes';
import { Mensagens } from '../../../sistema/@types/types';

export type TCliente = {
  cpf: string;
  nome: string;
  idGenero: number;
  dataNascimento: string;
  telefone: string;
  email: string;
};

export const TClienteVazio: TCliente = {
  cpf: EmptyStr,
  nome: EmptyStr,
  idGenero: 1,
  dataNascimento: EmptyStr,
  telefone: EmptyStr,
  email: EmptyStr,
};

export type TGenero = {
  id: number;
  nome: string;
};

export type TJsonEnvioCadastroCliente = {
  cliente: {
    cpf: string;
    nome: string;
    generoId: number;
    dataNascimento: string;
    telefone: string;
    email: string;
  };
};

export type TCadastroClienteResposta = {
  response: {
    data: {
      retorno: {
        status: string;
        codigo_status: number;
        mensagens: Array<Mensagens>;
      };
    };
  };
};
